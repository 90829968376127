
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ClaimsTable from "@/modules/claims/ClaimsTable.vue";

export interface Data {
  items: [];
}

export default defineComponent({
  name: "ClaimsListPage",
  components: { ClaimsTable },
  setup() {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({ items: [] });
    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Claim Management");
    });

    return {
      organizationId,
      data,
    };
  },
});
