
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { getPanel } from "@/api/encounter.api";
import { searchClaims } from "@/api/claim.api";
import { addBatch } from "@/api/batch.api";
import { DictionaryModel } from "@/modules/common/dictionary.model";
import { useStore } from "vuex";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  items: [];
  selectedItems: [];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  panel: any;
  claimStatuses: DictionaryModel[];
  formType: string;
}
export default defineComponent({
  name: "ClaimsTable",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const orderList = [
      { name: "Encounter Id", key: "encounterId" },
      { name: "Payer Id", key: "PayerId" },
      { name: "Type Of Bill", key: "typeOfBill" },
      { name: "DischargeStatus", key: "dischargeStatus" },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      selectedItems: [],
      keyword: "",
      orderBy: "encounterId",
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
      panel: {},
      claimStatuses: [],
      formType: "",
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.panel = await getPanel();
      data.claimStatuses = store.getters.allClaimStatuses;
      await getAll();
    });

    async function search() {
      await getAll();
    }

    async function changePage(index) {
      if (data.pagination.currentPage == 1 && index < 0) return;
      if (
        data.pagination.currentPage == data.pagination.totalPages &&
        index > 0
      )
        return;

      data.pagination.currentPage = data.pagination.currentPage + index;
      await getAll();
    }

    async function selectPage(index) {
      data.pagination.currentPage = index;
      await getAll();
    }

    async function getAll() {
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: [data.orderBy],
        isExtended: true,
      };

      const res = await searchClaims(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    function selectItem(item) {
      router.push({ name: "claimPage", params: { claimId: item.id } });
    }

    function statusDescription(code) {
      return data.claimStatuses.find((item) => {
        return item.code == code;
      })?.description;
    }

    function getFacility(facilityId) {
      return data.panel.facilities.find((item) => {
        return item.id == facilityId;
      });
    }

    async function createBatch() {
      router.push({
        name: "claimsBatchPage",
        params: {
          formType: data.formType,
          claims: data.selectedItems.map((item: any) => {
            return item.id;
          }),
        },
      });
    }

    return {
      organizationId,
      data,
      selectItem,
      orderList,
      selectPage,
      search,
      changePage,
      statusDescription,
      getFacility,
      createBatch,
    };
  },
});
